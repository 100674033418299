.map-container {
  height: 100vh;
  width: 100vw;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 10px;
  margin: 12px;
  border-radius: 4px;
  box-shadow: 5px 5px 36px -7px rgba(0, 0, 0, 0.46);
}

.searchBar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  width: 300px;
  margin: 22px;
  border-radius: 4px;
  box-shadow: 5px 5px 36px -7px rgba(0, 0, 0, 0.46);
  text-decoration: none;

}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.style-image {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  transition: opacity 100ms linear;
  margin-bottom: 30px;
}

.style-image:hover {
  opacity: 0.8;
}



.popup .mapboxgl-popup-content {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  border: none;
}

.mapboxgl-popup-close-button {
  display: none;
}

.centerBox {
  font-family: monospace;
  color: #fff;
  font-size: x-large;
  background-color: #282C35;
  max-width: 750px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.05);
  word-wrap: break-word;
  /* Flex box bullshit */
  display: flex;
  flex-direction: row;
  position: relative;
}

.centerBox .infoText {
  display: flex;
  flex-direction: column;
}

.nav-rat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding-bottom: 15px;
}

.mini-map-container {
  width: 375px;
  height: 500px;
  border-radius: 5px;
  margin-left: 10px;
}

.infoText {
  width: 375px;
}

@media only screen and (max-width: 800px) {
  .centerBox {
     max-width: 90vw;
    min-width: 70vw;
    flex-direction: column;
  }
  body {
    overflow: hidden
  }

  .mini-map-container {
    width: 100%;
    margin-left: 0px;
    height: 250px;
  }

  .nav-rat {
    position: relative;
    bottom: auto;
    padding-bottom: 0px;
  }
}

body {
  height: 100vh;
  background-color: #21252B;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}